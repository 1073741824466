document.addEventListener('DOMContentLoaded', () => {
  $(document).on('change', '#pwshippingestimate_se_country', (e) => {
    let country = $(e.currentTarget).val();
    updateCarriers(country);
  })

  $(document).on('change', '#pwshippingestimate_se_state', (e) => {
    let state = $(e.currentTarget).val();
    updateCarriers(state);
  })
});
function updateCarriers(data) {
	let country_array = data.split('-');
	let id_country = country_array[1];
	let id_state = country_array[2];

	let process_estimate = false;
	let getstates = false;

	if (parseInt(country_array[0]) === 1) {
		$('#ajaxarea').slideUp('normal');
		getstates = true;
	} else if (parseInt(country_array[0]) === 2) {
		document.getElementById('statearea').innerHTML = "";
		process_estimate = true;
	} else {
		process_estimate = true;
	}

	id_country = country_array[1];

	if (getstates) {
		$.ajax({
			type: 'GET',
			url:	pwshippingestimate.ajax,
			async: true,
			cache: false,
			data: 'action=getstates&id_country=' + id_country,
			success: function(data)
			{
				document.getElementById('pwshippingestimate_se_state').innerHTML = getStateSelect(data);
				$('#statearea').show();
			}
		});
	}

	if (process_estimate) {
		$.ajax({
			type: 'GET',
			url:	pwshippingestimate.ajax,
			async: true,
			cache: false,
			data: 'id_country=' + id_country + '&id_state=' + id_state,
			success: function(data)
			{
				console.log(data)
				$('#ajaxarea').slideUp('normal');
				document.getElementById('ajaxarea').innerHTML = data;
				$('#ajaxarea').slideDown('normal');
			}
		});
	}
}

function getStateSelect(data) {
	let options = '';

	Object.entries(data.states).forEach(([key, value]) => {
		options += `<option value="3-${data.id_country}-${value.id_state}">${value.name}</option>`;
	})

	return options;
}